<template>
  <div class="card card-custom card-stretch gutter-b" :class="bgColor">
    <div class="card-body">
      <span class="svg-icon svg-icon-3x svg-icon-white d-block my-2">
        <inline-svg :src="icon" />
      </span>
      <div class="font-weight-bolder font-size-h2 mt-3" :class="textColor">{{ value }}</div>
      <span class="font-weight-bold font-size-lg mt-1" :class="textColor">{{ title }}</span>
    </div>
  </div>
</template>

<script>
// import { computed } from "@vue/composition-api";

export default {
  props: {
    bgColor: {
      default: "bg-primary",
      type: String
    },
    textColor: {
      default: "text-inverse-primary",
      type: String
    },
    icon: {
      default: "/media/svg/icons/Media/Equalizer.svg",
      type: String
    },
    title: {
      default: "",
      type: String
    },
    value: {
      default: "",
      type: String
    }
  }

  //   setup(props) {
  //     return {
  //       chartOptions,
  //       series
  //     };
  //   }
};
</script>
