<template>
  <div class="card card-custom bg-gray-100 gutter-b card-stretch card-shadowless">
    <div class="card-header h-auto border-0">
      <div class="card-title py-5">
        <h3 class="card-label">
          <span class="d-block text-dark font-weight-bolder">Outbound Transaction</span>
          <span class="d-block text-dark-50 mt-2 font-size-sm">Showing outbound transaction for last 10 days</span>
        </h3>
      </div>
    </div>
    <div class="card-body">
      <apexchart type="line" height="250" :options="chartOptions" :series="series"></apexchart>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/composition-api";

export default {
  props: ["value"],
  setup(props) {
    const getKey = data => {
      return Object.keys(data).sort();
    };

    const getXAxis = computed(() => {
      if (props.value) {
        var keys = getKey(props.value);
        let xAxisData = [];
        keys.forEach(k => xAxisData.push(k));
        return xAxisData;
      } else {
        return [];
      }
    });

    const chartOptions = computed(() => {
      return {
        chart: {
          id: "outbound-line-chart"
        },
        xaxis: {
          categories: getXAxis.value
        },
        colors: ["#FF0000"]
      };
    });

    const series = computed(() => {
      if (props.value) {
        var keys = getKey(props.value);
        let seriesData = [];
        keys.forEach(k => seriesData.push(props.value[k]));
        return [
          {
            name: "Outbound",
            data: seriesData
          }
        ];
      } else {
        return [
          {
            name: "Outbound",
            data: []
          }
        ];
      }
    });

    return {
      chartOptions,
      series
    };
  }
};
</script>
